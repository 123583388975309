import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useWoody } from '@/src/services/woody/woody';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { googleDriveQueryKeys } from '../queries/googleDriveQueryKeys';
import { googleDriveMutationKeys } from './googleDriveMutationKeys';

export const useMutationCreateGoogleDriveMirror = () => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  return useMutation({
    mutationKey: googleDriveMutationKeys.createGoogleDriveMirror(),
    mutationFn: async ({ accountId, driveId }: { accountId: string; driveId: string }) => {
      // this ideally should not happen
      if (!isLoggedIn) {
        return;
      }
      const response = await client.createGoogleDriveMirror(accountId, driveId);

      if (response.error) {
        throw response.error;
      }

      return response.data;
    },
    onSuccess: (_, { accountId }) => {
      /**
       * invalidate the query to refetch the data
       */
      queryClient.invalidateQueries({
        queryKey: googleDriveQueryKeys.googleDriveAccountDrives(accountId),
      });

      track(AnalyticsEvents.ConnectedGoogleDrive, { accountId });
    },
  });
};
