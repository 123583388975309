import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { connectionQueryPredicates } from '@/src/modules/connections/queries/connectionQueryPredicates';
import { rootQueryPredicates } from '@/src/modules/resource-roots/queries/rootQueryPredicates';
import { useWoody } from '@/src/services/woody/woody';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useMutationDeleteConnection = () => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();
  const { track } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (integrationId: string) => {
      if (!isLoggedIn) {
        return;
      }

      await client.v2(
        {
          endpoint: '/v2/integrations/{integrationId}',
          params: {
            integrationId: integrationId,
          },
        },
        {
          method: 'delete',
        },
      );
    },
    onSuccess: (_, integrationId) => {
      queryClient.invalidateQueries({
        predicate: (q) =>
          connectionQueryPredicates.integrationByExternalIdAll(q) ||
          rootQueryPredicates.resourceRootAll(q),
      });

      track(AnalyticsEvents.DeletedGithubAccount, { integrationId: integrationId });
    },
  });
};
