import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useWoody } from '@/src/services/woody/woody';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { googleDriveQueryKeys } from '../queries/googleDriveQueryKeys';
import { googleDriveMutationKeys } from './googleDriveMutationKeys';

export const useMutationDeleteGoogleDriveMirror = () => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  return useMutation({
    mutationKey: googleDriveMutationKeys.deleteGoogleDriveMirror(),
    mutationFn: async (folderId: string) => {
      // this ideally should not happen
      if (!isLoggedIn) {
        return;
      }
      const response = await client.deleteGoogleDriveMirror(folderId);

      if (response.error) {
        throw response.error;
      }
    },
    onSuccess: (_, folderId) => {
      /**
       * invalidate the query to refetch the data
       */
      queryClient.invalidateQueries({
        queryKey: googleDriveQueryKeys.googleDriveAccountDrives(folderId),
      });

      track(AnalyticsEvents.DeletedGoogleDrive, { folderId });
    },
  });
};
