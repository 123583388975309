import { rootQueryKeys } from '@/src/modules/resource-roots/queries/rootQueryKeys';
import { Query } from '@tanstack/react-query';

const rootQueryPredicates = {
  resourceRootAll: (query: Query) => query.queryKey[0] === rootQueryKeys.resourceRootList()[0],
  resourceRoot: (query: Query, resourceId: string) =>
    query.queryKey[0] === rootQueryKeys.resourceRoot(resourceId)[0],
};

export { rootQueryPredicates };
