import { connectionQueryKeys } from '@/src/modules/connections/queries/connectionQueryKeys';
import { Query } from '@tanstack/react-query';

export const connectionQueryPredicates = {
  integrationByExternalIdAll: (query: Query) => {
    const integrationKey = connectionQueryKeys.integrationByExternalId('');
    return query.queryKey[0] === integrationKey[0];
  },
  integrationByExternalId: (query: Query, id: string) => {
    const integrationKey = connectionQueryKeys.integrationByExternalId(id);
    return query.queryKey[0] === integrationKey[0] && query.queryKey[1] === integrationKey[1];
  },
};
