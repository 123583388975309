import { rootQueryPredicates } from '@/src/modules/resource-roots/queries/rootQueryPredicates';
import { useQueryClient } from '@tanstack/react-query';

export const useInvalidateResourceRootLists = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      predicate: (q) => rootQueryPredicates.resourceRootAll(q),
    });
};
