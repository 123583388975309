import { ErrorMessages } from '@/src/core/config/errors';
import { ApiIntegration } from '@/src/modules/connections/connections.types';
import { useMutationDeleteConnection } from '@/src/modules/connections/mutations/useMutationDeleteConnection';
import { useInvalidateResourceRootLists } from '@/src/modules/resource-roots/queries/useInvalidateResourceRootLists';
import { toast } from '@/src/store/alerts';
import useScreenshotsStore from '@/src/store/screenshots';
import { getErrorType } from '@/src/utils/error';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { GoogleConfirmArguments, useGoogleDeleteMirror, useGooglePending } from './google';

export enum Connection {
  GOOGLE = 'google',
  GITHUB = 'github',
  UNKNOWN = 'unknown',
}

export const connectionNames: {
  [key in Connection]: string;
} = {
  [Connection.GOOGLE]: 'Google Drive',
  [Connection.GITHUB]: 'GitHub',
  [Connection.UNKNOWN]: 'Unknown',
};

type PendingConnectionBase = {
  iconUrl?: string;
  url?: string;
  name: string;
  accountName: {
    value: string;
    parts: string[];
  };
};

type PendingGoogleConnection = PendingConnectionBase & {
  connection: Connection.GOOGLE;
  arguments: GoogleConfirmArguments;
};

type PendingGithubConnection = PendingConnectionBase & {
  connection: Connection.GITHUB;
};

type PendingConnection = PendingGoogleConnection | PendingGithubConnection;

type ConnectionStore = {
  pendingConnection: PendingConnection | null;
  setPendingConnection: (connection: PendingConnection | null) => void;
};

export const useConnectionStore = create<ConnectionStore>()((set) => ({
  pendingConnection: null,
  setPendingConnection: (connection) => set({ pendingConnection: connection }),
}));

const connectionRoute: Record<Connection, string> = {
  [Connection.GOOGLE]: '/connections/google/account-selector',
  [Connection.GITHUB]: '/add-connection',
  [Connection.UNKNOWN]: '/add-connection',
};

export const usePendingConnection = () => {
  const { pendingConnection, setPendingConnection } = useConnectionStore();
  const router = useRouter();

  const invalidateResourceRoots = useInvalidateResourceRootLists();

  const googleConfirm = useGooglePending();

  const integrationConfirm = async (connection: PendingConnection) => {
    switch (connection.connection) {
      case Connection.GOOGLE:
        return googleConfirm(connection.arguments);
    }
  };

  const confirmPendingConnection = async () => {
    if (!pendingConnection) return;

    try {
      const id = await integrationConfirm(pendingConnection);
      await invalidateResourceRoots();
      await router.replace(`/folders/${id}`);
      setPendingConnection(null);
    } catch (error) {
      const errorType = getErrorType(error);
      toast({
        content: errorType
          ? ErrorMessages[errorType]
          : 'Something went wrong while connecting your account.',
      });
      await router.replace(connectionRoute[pendingConnection.connection]);
      setPendingConnection(null);
    }
  };

  const cancelPendingConnection = async () => {
    if (!pendingConnection) return;

    await router.replace(connectionRoute[pendingConnection.connection]);
    setPendingConnection(null);
  };

  return {
    pendingConnection,
    confirmPendingConnection,
    cancelPendingConnection,
  };
};

export const useRemoveConnection = () => {
  const deleteGoogleDriveMirror = useGoogleDeleteMirror();
  const { mutateAsync: mutateDeleteConnection } = useMutationDeleteConnection();
  const reset = useScreenshotsStore((state) => state.reset, shallow);
  const invalidateResourceRoots = useInvalidateResourceRootLists();

  const removeConnection = useCallback(
    async (integration: Pick<ApiIntegration, 'id' | 'type'>) => {
      try {
        switch (integration.type) {
          case 'GOOGLE_DRIVE': {
            await deleteGoogleDriveMirror(integration.id);
            invalidateResourceRoots();
            return true;
          }
          case 'ANDROID_DEVICE':
          case 'IOS_DEVICE': {
            await mutateDeleteConnection(integration.id);
            await invalidateResourceRoots();
            // We make sure to reset the iOS screenshot store to avoid the UI
            // showing the "Connected" state on the Add Connection page, and also
            // for it to get stuck if the user re-adds the connection.
            reset();
            return true;
          }
          case 'GITHUB': {
            await mutateDeleteConnection(integration.id);
            await invalidateResourceRoots();
            return true;
          }
          default:
            throw new Error('Integration removal not implemented.');
        }
      } catch (error) {
        const errorType = getErrorType(error);
        toast({
          content: errorType
            ? ErrorMessages[errorType]
            : 'Something went wrong while removing the connection.',
        });
        return false;
      }
    },
    [deleteGoogleDriveMirror, mutateDeleteConnection, reset, invalidateResourceRoots],
  );

  return removeConnection;
};
